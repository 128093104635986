import '@webcomponents/shadydom';
import 'wicg-inert';
import 'details-element-polyfill';
import React from 'react';
import { MenuProvider } from './src/context';
import tealium from './src/utils/tealium';

export const wrapRootElement = ({ element }) => (
  <MenuProvider>{element}</MenuProvider>
);

export const onRouteUpdate = () => {
  if (typeof window !== "undefined") {
    if (window.location.pathname.includes('essential-reading')) {
      /* tealium.view({
        page_subcategory: "article",
      }); */
      if (typeof window !== "undefined") {
        if(window.utag){
          window.utag.view({page_subcategory: "article"})
        }
      }
    } else if (window.location.pathname.includes('pages')) {
      /* tealium.view({
        page_subcategory: "magazine",
      }); */
      if (typeof window !== "undefined") {
        if(window.utag){
          window.utag.view({page_subcategory: "magazine"})
        }
      }
    } else if (window.location.pathname.includes('criteria')) {
      /* tealium.view({
        page_subcategory: "criteria",
      }); */
      if (typeof window !== "undefined") {
        if(window.utag){
          window.utag.view({page_subcategory: "criteria"})
        }
      }
    } else if (window.location.pathname.includes('calculator')) {
      if (typeof window !== "undefined") {
        if(window.utag){
          window.utag.view({page_subcategory: "calculator"})
        }
      }
    }
  }
};